/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~material-design-icons/iconfont/material-icons.css";
@import "~highlight.js/styles/googlecode.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@font-face {
  font-family: "Bahij Halvetica";
  src: url("assets/fonts/bahij_helvetica_neue_bold_by_bahijvirtualacademy-dammcmt_0.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "Bahij Halvetica";
  src: url("assets/fonts/Bahij_Helvetica_Neue-Light_1.ttf");
  font-weight: lighter;
}
@font-face {
  font-family: "Bahij Halvetica";
  src: url("assets/fonts/bahij_helvetica_neue_roman_by_bahijvirtualacademy-dammd50_0.ttf");
  font-weight: normal;
}
.kt-wizard-v4 {
  flex-direction: column;
}
.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
}
.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex: 0 0 calc(25% - 0.25rem);
  width: calc(25% - 0.25rem);
  background-color: #f7f8fb;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  cursor: pointer;
}
@media (max-width: 1399px) {
  .kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item {
    flex: 0 0 calc(50% - 0.25rem);
    width: calc(50% - 0.25rem);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item .kt-wizard-v4__nav-body {
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #959cb6;
  padding: 2rem 2.5rem;
}
.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item .kt-wizard-v4__nav-body .kt-wizard-v4__nav-number {
  font-size: 1.3rem;
  font-weight: 600;
  flex: 0 0 2.75rem;
  height: 2.75rem;
  width: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(89, 29, 241, 0.08);
  color: #591df1;
  margin-right: 1rem;
  border-radius: 0.5rem;
}
.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item .kt-wizard-v4__nav-body .kt-wizard-v4__nav-label {
  display: flex;
  flex-direction: column;
}
.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item .kt-wizard-v4__nav-body .kt-wizard-v4__nav-label .kt-wizard-v4__nav-label-title {
  font-size: 1.1rem;
  font-weight: 600;
}
.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item[data-ktwizard-state=current] {
  background-color: #ffffff;
}
.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item[data-ktwizard-state=current] .kt-wizard-v4__nav-body .kt-wizard-v4__nav-number {
  color: #ffffff;
  background-color: #591df1;
}
.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item[data-ktwizard-state=current] .kt-wizard-v4__nav-body .kt-wizard-v4__nav-label .kt-wizard-v4__nav-label-title {
  color: #591df1;
}
@media (max-width: 768px) {
  .kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items {
    flex-direction: column;
    align-items: flex-start;
  }
  .kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item {
    flex: 0 0 100%;
    position: relative;
    width: 100%;
  }
  .kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item .kt-wizard-v4__nav-body {
    justify-content: flex-start;
    flex: 0 0 100%;
    padding: 0.5rem 2rem;
  }
}
.kt-wizard-v4 .kt-wizard-v4__wrapper {
  display: flex;
  justify-content: center;
}
.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form {
  width: 60%;
  padding: 4rem 0 5rem;
}
.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-wizard-v4__content {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #eeeef4;
}
.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-wizard-v4__content .kt-wizard-v4__form {
  margin-top: 3rem;
}
.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-wizard-v4__content .kt-wizard-v4__review .kt-wizard-v4__review-item {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eeeef4;
}
.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-wizard-v4__content .kt-wizard-v4__review .kt-wizard-v4__review-item .kt-wizard-v4__review-title {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.7rem;
}
.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-wizard-v4__content .kt-wizard-v4__review .kt-wizard-v4__review-item .kt-wizard-v4__review-content {
  line-height: 1.8rem;
}
.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-wizard-v4__content .kt-wizard-v4__review .kt-wizard-v4__review-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form__actions {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form__actions .btn {
    margin: 0 0.5rem 1rem;
  }
}
.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form__actions [data-ktwizard-type=action-prev] {
  margin-right: auto;
}
@media (max-width: 576px) {
  .kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form__actions [data-ktwizard-type=action-prev] {
    margin-right: 0.5rem;
  }
}
.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form__actions [data-ktwizard-type=action-next] {
  margin: auto 0 auto auto;
}
@media (max-width: 576px) {
  .kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form__actions [data-ktwizard-type=action-next] {
    margin: 0 0 1rem;
  }
}
@media (max-width: 768px) {
  .kt-wizard-v4 .kt-wizard-v4__wrapper {
    padding: 2rem;
  }
  .kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form {
    width: 100%;
    padding: 2rem 1rem 4rem;
  }
}
.kt-wizard-v4 [data-ktwizard-type=step-info] {
  display: none;
}
.kt-wizard-v4 [data-ktwizard-type=step-info][data-ktwizard-state=current] {
  display: block;
}
.kt-wizard-v4 [data-ktwizard-type=step-content] {
  display: none;
}
.kt-wizard-v4 [data-ktwizard-type=step-content][data-ktwizard-state=current] {
  display: block;
}
.kt-wizard-v4 [data-ktwizard-type=action-prev] {
  display: none;
}
.kt-wizard-v4 [data-ktwizard-type=action-next] {
  display: inline-block;
}
.kt-wizard-v4 [data-ktwizard-type=action-submit] {
  display: none;
}
.kt-wizard-v4[data-ktwizard-state=first] [data-ktwizard-type=action-prev] {
  display: none;
}
.kt-wizard-v4[data-ktwizard-state=first] [data-ktwizard-type=action-next] {
  display: inline-block;
}
.kt-wizard-v4[data-ktwizard-state=first] [data-ktwizard-type=action-submit] {
  display: none;
}
.kt-wizard-v4[data-ktwizard-state=between] [data-ktwizard-type=action-prev] {
  display: inline-block;
}
.kt-wizard-v4[data-ktwizard-state=between] [data-ktwizard-type=action-next] {
  display: inline-block;
}
.kt-wizard-v4[data-ktwizard-state=between] [data-ktwizard-type=action-submit] {
  display: none;
}
.kt-wizard-v4[data-ktwizard-state=last] [data-ktwizard-type=action-prev] {
  display: inline-block;
}
.kt-wizard-v4[data-ktwizard-state=last] [data-ktwizard-type=action-next] {
  display: none;
}
.kt-wizard-v4[data-ktwizard-state=last] [data-ktwizard-type=action-submit] {
  display: inline-block;
}

/* // Responsive mode(ref $kt-media-breakpoints in framework\_config.scss)
// Above 1200px
@include kt-media-above(xl) {
	.kt-wizard-v4 {

	}
}

// Above 1024px and below 1200px
@include kt-media-range(lg, xl) {
	.kt-wizard-v4 {

	}
}

// Below 1024px
@include kt-media-below(lg) {
	.kt-wizard-v4 {

	}
} */
.dropdown-toggle::after {
  display: none !important;
}

.mat-table__wrapper--dashboard .mat-table {
  min-width: 600px !important;
  width: 100% !important;
}

.no-paginator-range-label {
  margin-top: 3rem;
}
.no-paginator-range-label .mat-paginator-range-label {
  display: none !important;
}

.position-static {
  position: static;
}

.kt-wizard-v4 [data-ktwizard-type=step-content][data-ktwizard-state=current] {
  width: 60% !important;
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form {
  width: auto !important;
}

._form_wrapper {
  padding: 3rem 0rem;
}

._abs_btn {
  position: absolute;
  top: 28%;
}

button:disabled {
  cursor: not-allowed !important;
}

.form-group.required .col-form-label:after {
  content: "  *";
  color: red;
}